<template>
  <y_layout>
    <div slot="left">
      <el-menu router :default-active="$route.path" style="height: 100%">
        <el-menu-item index="/system/entry" :route="{name:'systemEntry'}">入口链接</el-menu-item>
        <el-menu-item index="/system/sys-notice" :route="{name:'systemSysNotice'}">系统公告</el-menu-item>
        <el-menu-item index="/system/banner" :route="{name:'systemBanner'}">首页轮播</el-menu-item>
        <el-menu-item index="/system/gird" :route="{name:'systemGird'}">首页宫格</el-menu-item>
        <el-menu-item index="/system/poster" :route="{name:'systemPoster'}">邀请海报</el-menu-item>
        <el-menu-item index="/system/info" :route="{name:'systemInfo'}">基础配置</el-menu-item>

        <el-menu-item index="/system/wx-official" :route="{name:'systemWxOfficial'}">微公众号</el-menu-item>
        <el-menu-item index="/system/wxapp" :route="{name:'systemWxapp'}">微小程序</el-menu-item>
        <el-menu-item index="/system/wx-pay" :route="{name:'systemWxPay'}">微信支付</el-menu-item>
        <el-menu-item index="/system/ali-pay" :route="{name:'systemAliPay'}">阿里支付</el-menu-item>
        <el-menu-item index="/system/sns" :route="{name:'systemSns'}">短信配置</el-menu-item>
      </el-menu>
    </div>
    <router-view/>
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";

export default {
  name: "index",
  components: {y_layout},
}
</script>

<style scoped>

</style>